export const superAdminMenuItems = [{
        id: 1,
        title: "SUPER ADMIN MENU",
        isTitle: true
    },

    {
        id: 31,
        title: 'menuitems.home.text',
        icon: 'ri-dashboard-line',
        link: '/admin/home'
    },
    {
        id: 32,
        title: 'menuitems.organizations.text',
        icon: 'ri-dashboard-line',
        subItems: [{
                id: 33,
                title: 'menuitems.organizations.list.organization',
                link: '/admin/manage-organizations'
            },
            {
                id: 34,
                title: 'menuitems.organizations.list.admins',
                link: '/admin/org/users'
            },
        ]
    },

    {
        id: 1,
        title: "OPERATIONS MENU",
        isTitle: true
    },

    
    {
        id: 2,
        title: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },

    {
        id: 3,
        title: 'menuitems.remittances.text',
        icon: 'ri-exchange-dollar-line',
        subItems: [{
                id: 4,
                title: 'menuitems.remittances.list.remittances',
                link: '/remittances'
            },
            {
                id: 5,
                title: 'menuitems.remittances.list.reversals',
                link: '/remittances/reversals'
            }
        ]
    },

    // Branch functions
    {
        id: 7,
        title: 'menuitems.branchmanagement.text',
        icon: 'ri-bank-line',
        subItems: [
            {
                id: 6,
                title: 'menuitems.shops.text',
                
                link: '/shops'
            },
            {
                id: 8,
                title: 'menuitems.branchmanagement.list.branchfunding',
                link: '/branch/branch-funding'
            },
            {
                id: 10,
                title: 'menuitems.branchmanagement.list.closevault',
                link: '/branch/close-branch-vault'
            },
        ]
    },

    //Till functions
    {
        id: 7,
        title: 'menuitems.till.text',
        icon: 'mdi mdi-safe-square-outline',
        subItems: [
            {
                id: 9,
                title: 'menuitems.till.list.tillfunding',
                link: '/till/till-funding'
            },
            {
                id: 10,
                title: 'menuitems.till.list.closetill',
                link: '/till/close-till'
            },
        ]
    },

    {
        id: 11,
        label: 'menuitems.accounts.text',
        icon: 'ri-bank-line',
        subItems: [{
                id: 4,
                label: 'menuitems.accounts.list.accounts',
                link: '/accounts'
            },
            { 
                id: 5,
                label: 'menuitems.accounts.list.fundstransfer',
                link: '/funds-transfer'
            }
        ]
    },

    {
        id: 12,
        title: 'menuitems.usermanagement.text',
        icon: 'dripicons-user-group',
        subItems: [{
                id: 13,
                title: 'menuitems.usermanagement.list.usermanagement',
                link: '/users'
            },
            {
                id: 14,
                title: 'menuitems.usermanagement.list.registeruser',
                link: '/users/registration'
            },
        ]
    },
    {
        id: 15,
        title: 'menuitems.reports.text',
        icon: 'ri-file-paper-2-line',
        subItems: [{
                id: 16,
                title: 'menuitems.reports.list.remittanceReports',
                link: '/reports/remittances'
            },
            {
                id: 17,
                title: 'menuitems.reports.list.customerReports',
                link: '/reports/customers'
            },
        ]
    },
    {
        id: 18,
        title: 'menuitems.advertisements.text',
        icon: 'fa fa-ad',
        link: '/advertisements'
    },

    {
        id: 15,
        title: 'menuitems.configuration.text',
        icon:   'ri-settings-2-line',
        subItems: [
            {
                id: 19,
                title: 'menuitems.configuration.list.businessconditions',
                link: '/business-conditions'
            },
            {
                id: 19,
                title: 'menuitems.configuration.list.alerts',
                link: '/alerts'
            },
            {
                id: 19,
                title: 'menuitems.configuration.list.roles',
                link: '/roles'
            },

        ]
    },


]